
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import moment from 'moment';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import { getChannel } from '../factory/api/channel'
import {
  queryFactory,
} from '@/api/factory/factory';
// api
import { queryOut, logisticsOut, getOther } from '@/api/storage/out'

// 组件
import CustomModal from '@/component/custom-modal.vue';
const STATUS_TYPE = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '待出库',
  },
  {
    id: 2,
    value: '已出库',
  },
]
const STORAGE_TYPE = [
  // {
  //   id: 0,
  //   value: '全部',
  // },
  // {
  //   id: 1,
  //   value: '销售出库',
  // },
  {
    id: 2,
    value: '发货出库',
  },
  // {
  //   id: 3,
  //   value: '门店退货出库',
  // },
]
@Component({
  name:'StorageOut',
  components: {
    CustomModal,
  },
})
export default class StorageOut extends Mixins(Validate) {
  moment: any = moment;
  is_loading: boolean = false;
  next_page: number = 1;
  type: number = 0;
  editType: boolean = true;
  purchase_data: any = {
    current: 1
  }
  repo_data: any = {
    current: 1
  }
  // 表单布局
  form_item_layout: any = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };
  model_layout: any = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  get statusType() {
    return STATUS_TYPE;
  }

  get storageType() {
    return STORAGE_TYPE;
  }
  seach: any = {
    code: '',
    biz_code: '',
    type: 2,
    status: 0,
    from: '',
    to: '',
  }
  validator_list: any[] = [
    {
      field: 'plate_number',
      message: '请输入车牌号',
    },
    {
      field: 'driver_name',
      message: '请输入司机姓名',
    },
    {
      field: 'driver_mobile',
      message: '请填写正确的11位手机号码',
      validate: (value: string): boolean => {
        if (value === '' || this.checkTel(value)) {
          return !!value;
        } else {
          return false;
        }

      },
    },
  ];
  form: any = {
    id: '',
    plate_number: '',
    driver_name: '',
    driver_mobile: '',
  }
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  // 确认修改状态弹窗
  add_modal: any = {
    title: '',
    visible: false,
    is_loading: false,
  }
  // 失败弹窗
  fail_modal: any = {
    title: '抱歉，因以下原因接单失败',
    visible: false,
    content: [],
  };
  add_other_modal: any = {
    visible: false,
    is_loading: false
  }
  add_other_form: any = {
    factory_id: null,
    channel_id: null,
    channel_name: null,
    biz_code: "",
    description: ""
  }
  channel_list: any[] = []
  factory_list: any[] = []
  async getChannel() {
    const res = await getChannel({ limit: 1000 }, 1)
    this.channel_list = res.data.detail ? res.data.detail : []
  }
  // 新增编辑校验
  checkTel(value: any) {
    const isMob = /^((\+?86)|(\(\+86\)))?(13[0123456789][0-9]{8}|15[0123456789][0-9]{8}|18[02356789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$/;
    if (isMob.test(value)) {
      return true;
    } else {
      return false;
    }
  }
  addOtherModal() {
    this.add_other_modal.visible = true
  }
  otherCancel() {
    this.initModel()
    this.add_other_modal.visible = false
  }
  changeChannel(e: any) {
    console.log(e);
    this.add_other_form.channel_id = e.id
    this.add_other_form.channel_name = e.name
  }
  async otherSubmit() {
    if (!this.add_other_form.factory_id) {
      this.$message.error('请选择发货工厂')
      return
    }
    if (!this.add_other_form.channel_id || !this.add_other_form.channel_name) {
      this.$message.error('请选择渠道商')
      return
    }
    if (!this.add_other_form.biz_code) {
      this.$message.error('请输入订单号')
      return
    }
    const send_data = {
      factory_id: this.add_other_form.factory_id,
      channel_id: this.add_other_form.channel_id,
      channel_name: this.add_other_form.channel_name,
      biz_code: this.add_other_form.biz_code,
      description: this.add_other_form.description || "",
    }
    const res = await getOther(send_data)
    if (res.status === 200) {
      this.$message.success('新增其他出库成功')
      this.add_other_modal.visible = false
      await this.fetchList(1);
      this.initModel()
      this.add_other_modal.visible = false
    } else {
      this.$message.success((res as any).message)
    }
  }
  initModel() {
    this.add_other_form.factory_id = null
    this.add_other_form.channel_id = null
    this.add_other_form.channel_name = null
    this.add_other_form.biz_code = ""
    this.add_other_form.description = ""
  }
  async getFactoryList() {
    const res = await queryFactory(1, { limit: 1000 });
    this.factory_list = res.detail ? res.detail : []
  }
  // 获取产品列表
  async fetchList(current: number = 1) {
    this.purchase_data = await queryOut(current);
  }
  // 搜索
  async query() {
    await this.fetchSeachList();
  }
  @changeLoading(['is_loading'])
  async fetchSeachList(current: number = 1) {
    const send_data: any = {
      code: this.seach.code,
      biz_code: this.seach.biz_code,
      // type: 2,
      status: this.seach.status,
      from: this.seach.from,
      to: this.seach.to,
    };
    for (const key in send_data) {
      if (send_data[key] === '') delete send_data[key];
    }
    this.purchase_data = await queryOut(current, send_data);
  }
  // 搜索清空重置
  async resetFilter() {
    this.seach = {
      code: '',
      biz_code: '',
      type: 2,
      status: 0,
      from: '',
      to: '',
    };
    this.query()
    await this.fetchList();
  }
  onTableChange(pagination: any) {
    this.next_page = pagination.current;
    this.fetchSeachList(this.next_page);
  }
  //分页
  paginationOption(data: any) {
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }

  filterOption(input: any, option: any) {
    return (
      option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }

  // 物流
  add(e: any) {
    this.add_modal.title = '物流追踪';
    this.add_modal.visible = true;
    console.log(e)
    if (e.plate_number == '') {
      this.type = 0;
      this.editType = false;
      this.add_modal.data = e
    } else {
      this.type = 1;
      this.editType = true;
      this.form = {
        id: e.id,
        plate_number: e.plate_number,
        driver_name: e.driver_name,
        driver_mobile: e.driver_mobile,
      };
    }
  }
  // 提交
  @changeLoading(['is_loading'])
  async submit() {
    console.log(this.type)
    // 校验
    if (!this.validateCommit()) {
      return;
    }
    this.add_modal.is_loading = true;
    const res = await logisticsOut(this.type == 0 ? this.add_modal.data.id : this.form.id, this.form);
    this.add_modal.is_loading = false;
    this.add_modal.visible = false;
    if (res.status !== 200) {
      this.fail_modal.content = [...(res as any).message.split('\n')];
      this.fail_modal.visible = true;
      return;
    }
    this.$message.success('追踪成功');
    this.clearModal();
    await this.fetchList();
  }
  // 取消
  cancel() {
    this.clearModal();
  }
  // 清空模态框数据
  clearModal() {
    // 清除校验信息
    this.add_modal = {
      title: '',
      visible: false,
      is_loading: false,
    }
    this.form = {
      id: '',
      plate_number: '',
      driver_name: '',
      driver_mobile: '',
    }
    this.$nextTick(() => {
      this.resetValidatorStatus();
    });
  }

  @changeLoading(['is_loading'])
  async created() {
    await this.fetchList(this.$route.query.page ? +this.$route.query.page : 1);
    await this.getChannel()
    await this.getFactoryList()
  }

}
